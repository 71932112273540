<template>
    <el-col class="page">
        <el-col class="title">我的关注</el-col>
        <el-col class="table-container">
            <el-table
                    :data="tableData"
                    border
                    width="1609px"
                    v-loading="loading"
            >
                <el-table-column prop="name" label="商品名称"></el-table-column>
                <el-table-column label="商品图片">
                    <template slot-scope="scope">
                        <span @mouseenter="pictureShow(scope.row.logoUrl)" @mouseleave="pictureHide">
                            <img style="width: 45px;height: 45px;margin-top:5px" :src="scope.row.logoUrl">
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="className" label="产品分类" ></el-table-column>
                <el-table-column prop="adapt" label="适用操作系统">
                    <template slot-scope="scope">
                        <el-col v-if="scope.row.adapt == 1">windows</el-col>
                        <el-col v-if="scope.row.adapt == 2">linux</el-col>
                        <el-col v-if="scope.row.adapt == 3">android</el-col>
                        <el-col v-if="scope.row.adapt == 4">ios</el-col>
                        <el-col v-if="scope.row.adapt == 5">unix</el-col>
                        <el-col v-if="scope.row.adapt == 6">其他</el-col>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsType" label="商品类型">
                    <template slot-scope="scope">
                        <el-col v-if="scope.row.goodsType == 1">镜像</el-col>
                        <el-col v-else-if="scope.row.goodsType == 2">人工服务</el-col>
                        <el-col v-else-if="scope.row.goodsType == 3">SaaS</el-col>
                        <el-col v-else-if="scope.row.goodsType == 4">解决方案</el-col>
                        <el-col v-else-if="scope.row.goodsType == 5">API</el-col>
                        <el-col v-else-if="scope.row.goodsType == 6">Licence</el-col>
                        <el-col v-else>硬件</el-col>
                    </template>
                </el-table-column>
                <el-table-column label="操作" class-name="table-col" width="200px">
                    <template slot-scope="scope">
                        <div>
                            <el-button v-if="scope.row.isSale == 0 || scope.row.marketDeleteFlag == 1" class="buy-now-isDisabled"  disabled>
                                立即购买
                            </el-button>
                            <el-button v-else class="buy-now" type="danger" @click="openProductDetail(scope.row)">
                                立即购买
                            </el-button>
                            <el-button style="width: 72px;height: 24px;margin: 0;" @click="dialogVisible = true ,row = scope.row">
                                取消关注
                            </el-button>
                        </div>

                    </template>
                </el-table-column>
            </el-table>
            <!--    点击取消关注时弹出提示框        -->
            <el-dialog
                    :visible.sync="dialogVisible"
                    title="取消关注提示"
                    width="30%"
                    :before-close="handleClose">
                <span>
                    <Icon type="ios-warning" style="font-size: 48px;color: rgb(255, 102, 0);" />
                    <p style="margin-left: 90px;display: inline-block;font-size: 14px">确定要取消关注吗？</p>
                </span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false" style="width: 60px;height: 40px;">取 消</el-button>
                    <el-button @click="unfollow(row)" type="danger" style="width: 60px;height: 40px;">确 定</el-button>
                </span>
            </el-dialog>
            <div class="logo-url" id="logo-url" style="display: none;"></div>
        </el-col>
        <el-col style="margin-top: 20px">
            <Page :total="page.total" show-total show-elevator style="font-size: 14px" :current="page.pageNo" :page-size="page.pageSize" @on-page-size-change="onPageSizeChange" @on-change="changePage" ></Page>
        </el-col>
    </el-col>
</template>

<script>
    import iView from 'iview'    //引入ivew库
    import 'iview/dist/styles/iview.css'    // 使用 CSS
    import Vue from 'vue'
    import concernApi from  '@/api/buyer/myConcern/concernApi.js'
    Vue.use(iView)
    export default {
        name: "Focus",
        data() {
            return {
              loading: true,
                row:{},
                page: {
                    pageNo: 1,
                    pageSize: 10,
                    total: 0
                },
                dialogVisible: false,
                tableData: []
            }
        },
        methods: {
            paneActive(id) {
                this.active = id;
            },
            handleClose(done) {
                this.dialogVisible = false;
            },
            pictureShow(logoUrl){
                // 显示图片板块
                var img = document.getElementById('logo-url');
                img.style.display = 'block';
                // 添加图片
                var addImg = document.createElement('img')
                addImg.setAttribute('style','width: 400px; height: 400px;');
                addImg.setAttribute('src',logoUrl);
                img.appendChild(addImg);
            },
            pictureHide(){
                document.getElementById('logo-url').children[0].remove();
                document.getElementById('logo-url').style.display = 'none';
            },
            // 查询关注列表
            getFollowList(){
                concernApi.getFollowList({pageNo:this.page.pageNo,pageSize:this.page.pageSize}).then((res) => {
                  this.timer = setTimeout(()=>{   //设置延迟执行
                    this.loading = false
                  },300)
                    if(res.data == null){
                        this.page.total = 0
                        this.tableData = []
                        return;
                    }
                    this.page.total = res.data.total
                    this.tableData = res.data.records
                })
            },
            onPageSizeChange(index) {
                this.page.pageSize = index;
                this.getFollowList();
             },
            changePage(index) {
                this.page.pageNo = index;
                this.getFollowList();
            },
            // 立即购买
            openProductDetail(row) {
              document.body.style.overflow = 'visible'
              // 云产品
              if (row.goodsType == '3'){
                this.$router.push({path:'/cloudMarket',query: {productId: row.goodsId, orderType: 1}})
              }
              else {
                this.$router.push({path:'/ProductDescription',query: {productId: row.goodsId}})

              }
            },
            // 取消关注
            unfollow(index) {
                this.dialogVisible = false
                console.log('index',index.flollowId)
                concernApi.syncFollowDel([index.flollowId]).then((res) => {
                    if(res.code == 200){
                        this.$message.success("取消关注成功")
                        this.getFollowList()
                    }else{
                        this.$message.error(res.message)
                    }
                })

            }
        },
        mounted() {
            this.getFollowList()
        }
    }
</script>

<style lang="less" scoped>
    .page {
        margin: 20px 35px;
        min-width: 1130px;
        width: 95%;

        .title {
            color: #F56E65;
            font-size: 24px;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }


        .table-container {
            margin-top: 20px;

            /deep/ .el-table .cell, .el-table--border .el-table__cell:first-child .cell {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            /deep/ .el-table th.el-table__cell {
                background-color: #f8f8f9;
            }

            /deep/ .el-table thead {
                color: #515A6E;
            }

            /deep/ .el-table td.el-table__cell {
                padding-top: 0;
                padding-bottom: 0;
            }
            /deep/ .el-table th.el-table__cell.is-leaf {
                /*padding-top: 0;*/
                /*padding-bottom: 0;*/

            }
            /deep/ .table-col {
                text-align: center;
            }
            /deep/ .el-button {
                padding: 0;
            }
            .logo-url {
                display: none;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                z-index: 11;
                height: 420px;
                width: 420px;
                background-color: #F56E65;
                padding: 10px;
            }
            .buy-now{
                width: 72px;
                height: 24px;
                margin-right: 15px;
            }
            .buy-now-isDisabled{
                width: 72px;
                height: 24px;
                margin-right: 15px;
                color: #c5c8ce;
                background-color: #f7f7f7;
                border-color: #dcdee2;
            }
            /deep/ .el-dialog {
                border-radius: 6px;
            }
            /deep/ .el-dialog__header{
                padding: 20px;
            }
            /deep/ .el-dialog__body {
                padding: 20px;
                border-bottom: 1px solid #e8eaec;
                border-top: 1px solid #e8eaec;
            }
            /deep/ .el-dialog__footer{
                padding: 10px 20px;
            }
        }

        /deep/ .ivu-page {
            float: right;
            margin-right: 143px;
        }
    }

</style>
