import {get,post} from "@/utils/axios"
// 获取我的关注列表
const getFollowList = p => get('/api-seller/follow/getFollowList', p);

// 取消关注
const syncFollowDel = p => post('/api-seller/follow/syncFollowDel', p);

export default {
    getFollowList,
    syncFollowDel
}
